import React from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import CryptoJS from 'crypto-js';

import '../fonts/fonts-post.css';
import Bio from '../components/Bio';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Signup from '../components/Signup';
import Panel from '../components/Panel';
import { formatPostDate, formatReadingTime } from '../utils/helpers';
import { rhythm, scale } from '../utils/typography';
import {
  codeToLanguage,
  createLanguageLink,
  loadFontsForCode,
} from '../utils/i18n';

const GITHUB_USERNAME = 'kdnc';
const GITHUB_REPO_NAME = 'kdnc.github.io';
const systemFont = `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif`;

class Translations extends React.Component {
  render() {
    let { translations, lang, languageLink, editUrl } = this.props;

    let readerTranslations = translations.filter(lang => lang !== 'ru');
    let hasRussianTranslation = translations.indexOf('ru') !== -1;

    return (
      <div className="translations">
        <Panel style={{ fontFamily: systemFont }}>
          {translations.length > 0 && (
            <span>
              {hasRussianTranslation && (
                <span>
                  Originally written in:{' '}
                  {'en' === lang ? (
                    <b>{codeToLanguage('en')}</b>
                  ) : (
                    <Link to={languageLink('en')}>English</Link>
                  )}
                  {' • '}
                  {'ru' === lang ? (
                    <b>Русский (авторский перевод)</b>
                  ) : (
                    <Link to={languageLink('ru')}>
                      Русский (авторский перевод)
                    </Link>
                  )}
                  <br />
                  <br />
                </span>
              )}
              <span>Translated by readers into: </span>
              {readerTranslations.map((l, i) => (
                <React.Fragment key={l}>
                  {l === lang ? (
                    <b>{codeToLanguage(l)}</b>
                  ) : (
                    <Link to={languageLink(l)}>{codeToLanguage(l)}</Link>
                  )}
                  {i === readerTranslations.length - 1 ? '' : ' • '}
                </React.Fragment>
              ))}
            </span>
          )}
          {lang !== 'en' && (
            <>
              <br />
              <br />
              {lang !== 'ru' && (
                <>
                  <Link to={languageLink('en')}>Read the original</Link>
                  {' • '}
                  <a href={editUrl} target="_blank" rel="noopener noreferrer">
                    Improve this translation
                  </a>
                  {' • '}
                </>
              )}
              <Link to={`/${lang}`}>View all translated posts</Link>{' '}
            </>
          )}
        </Panel>
      </div>
    );
  }
}

class BlogPostTemplate extends React.Component {
  state = {
    isLoggedIn: false,
    password: '',
  };

  componentDidMount() {
    this.collapseH4Elements();
  }

  collapseH4Elements() {
    const h4Elems = document.getElementsByTagName('h4');
    for (let i = 0; i < h4Elems.length; i++) {
      const h4Elem = h4Elems[i];
      const that = this;
      h4Elem.addEventListener('click', function() {
        that.toggleDisplay(h4Elem);
      });
      this.toggleDisplay(h4Elem);
    }
  }

  toggleDisplay(element) {
    let isNextElementSiblingNotAHeading = true;
    console.log(element);

    while (isNextElementSiblingNotAHeading) {
      let displayStyle = 'none';
      const nextElementSiblingTemp = element.nextElementSibling;
      if (nextElementSiblingTemp.style.display === 'none') {
        displayStyle = 'block';
      }
      nextElementSiblingTemp.style.display = displayStyle;
      const nextNextElementSibling = nextElementSiblingTemp.nextElementSibling;
      if (
        nextNextElementSibling instanceof HTMLHeadingElement ||
        nextNextElementSibling == null
      ) {
        isNextElementSiblingNotAHeading = false;
      } else {
        element = nextElementSiblingTemp;
      }
    }
  }

  componentDidUpdate() {
    // this.collapseH4Elements();
  }

  onPasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  onLogin = () => {
    var ciphertext = CryptoJS.SHA3(this.state.password).toString();
    this.setState({
      isLoggedIn:
        ciphertext ===
        '839956593894392635dc11f370474dd4cd03ddbea4e6065b619ef3789700ed8e97d632f2d5da0cf567645af5a9a69d02b4779add8ee7a443dd0d9523b5a55593',
    });
  };

  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');
    let {
      previous,
      next,
      slug,
      translations,
      translatedLinks,
    } = this.props.pageContext;
    const lang = post.fields.langKey;

    // Replace original links with translated when available.
    let html = post.html;
    translatedLinks.forEach(link => {
      // jeez
      function escapeRegExp(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }
      let translatedLink = '/' + lang + link;
      html = html.replace(
        new RegExp('"' + escapeRegExp(link) + '"', 'g'),
        '"' + translatedLink + '"'
      );
    });

    translations = translations.slice();
    translations.sort((a, b) => {
      return codeToLanguage(a) < codeToLanguage(b) ? -1 : 1;
    });

    loadFontsForCode(lang);
    // TODO: this curried function is annoying
    const languageLink = createLanguageLink(slug, lang);
    const enSlug = languageLink('en');
    const editUrl = `https://github.com/${GITHUB_USERNAME}/${GITHUB_REPO_NAME}/edit/master/src/pages/${enSlug.slice(
      1,
      enSlug.length - 1
    )}/index${lang === 'en' ? '' : '.' + lang}.md`;
    const discussUrl = `https://mobile.twitter.com/search?q=${encodeURIComponent(
      `https://overreacted.io${enSlug}`
    )}`;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          lang={lang}
          title={post.frontmatter.title}
          description={post.frontmatter.spoiler}
          slug={post.fields.slug}
        />
        <main>
          <article>
            <header>
              <h1 style={{ color: 'var(--textTitle)' }}>
                {post.frontmatter.title}
              </h1>
              <p
                style={{
                  ...scale(-1 / 5),
                  display: 'block',
                  marginBottom: rhythm(1),
                  marginTop: rhythm(-4 / 5),
                }}
              >
                {formatPostDate(post.frontmatter.date, lang)}
                {` • ${formatReadingTime(post.timeToRead)}`}
              </p>
              {translations.length > 0 && (
                <Translations
                  translations={translations}
                  editUrl={editUrl}
                  languageLink={languageLink}
                  lang={lang}
                />
              )}
            </header>
            {this.state.isLoggedIn ? (
              <div dangerouslySetInnerHTML={{ __html: html }} />
            ) : (
              <div>
                <input
                  type="password"
                  name="login"
                  onChange={this.onPasswordChange.bind(this)}
                />
                <button type="button" onClick={this.onLogin.bind(this)}>
                  Submit
                </button>
              </div>
            )}
            {/*<footer>*/}
            {/*  <p>*/}
            {/*    <a href={discussUrl} target="_blank" rel="noopener noreferrer">*/}
            {/*      Discuss on Twitter*/}
            {/*    </a>*/}
            {/*    {` • `}*/}
            {/*    <a href={editUrl} target="_blank" rel="noopener noreferrer">*/}
            {/*      Edit on GitHub*/}
            {/*    </a>*/}
            {/*  </p>*/}
            {/*</footer>*/}
          </article>
        </main>
        {/*<aside>*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      margin: '90px 0 40px 0',*/}
        {/*      fontFamily: systemFont,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <Signup />*/}
        {/*  </div>*/}
        {/*  <h3*/}
        {/*    style={{*/}
        {/*      fontFamily: 'Montserrat, sans-serif',*/}
        {/*      marginTop: rhythm(0.25),*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <Link*/}
        {/*      style={{*/}
        {/*        boxShadow: 'none',*/}
        {/*        textDecoration: 'none',*/}
        {/*        color: 'var(--pink)',*/}
        {/*      }}*/}
        {/*      to={'/'}*/}
        {/*    >*/}
        {/*      Overreacted*/}
        {/*    </Link>*/}
        {/*  </h3>*/}
        {/*  <Bio />*/}
        {/*  <nav>*/}
        {/*    <ul*/}
        {/*      style={{*/}
        {/*        display: 'flex',*/}
        {/*        flexWrap: 'wrap',*/}
        {/*        justifyContent: 'space-between',*/}
        {/*        listStyle: 'none',*/}
        {/*        padding: 0,*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <li>*/}
        {/*        {previous && (*/}
        {/*          <Link*/}
        {/*            to={previous.fields.slug}*/}
        {/*            rel="prev"*/}
        {/*            style={{ marginRight: 20 }}*/}
        {/*          >*/}
        {/*            ← {previous.frontmatter.title}*/}
        {/*          </Link>*/}
        {/*        )}*/}
        {/*      </li>*/}
        {/*      <li>*/}
        {/*        {next && (*/}
        {/*          <Link to={next.fields.slug} rel="next">*/}
        {/*            {next.frontmatter.title} →*/}
        {/*          </Link>*/}
        {/*        )}*/}
        {/*      </li>*/}
        {/*    </ul>*/}
        {/*  </nav>*/}
        {/*</aside>*/}
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        spoiler
      }
      fields {
        slug
        langKey
      }
    }
  }
`;
